:root {
  --font1: calc(20px + 2vmin);
  --font2: calc(10px + 2vmin);
  --dark: #37023C;
  --accent: #A046CB;
}


.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background: var(--dark);
  min-height: 8vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font1);
  color: white;
  padding: 2vmin 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 10px;
}

.grid-header {
  border-bottom: 1px solid black;
}

.links {
  margin-top: 5px;
  display: flex;
}

.player-tables {
  width: 35%;
  min-width: 350px;
  background: white;
}

.link {
  border: 1px black solid;
  border-radius: 3px 3px 0 0;
  padding: 6px 10px;
  text-decoration: none;
  flex-grow: 1;
  color: black;
  background: linear-gradient(0deg, rgba(214,214,214,1) 5%, rgba(255,255,255,1) 50%);
}

.link-points {
  font-size: 14px;
}

.link:hover {
  text-decoration: underline;
}

.active-link {
  border-bottom: none;
  background: white;
  /* font-size: var(--font2); */
  font-weight: 600;
  margin-top: -3px;
  color: var(--accent);
}

.position {
  text-align: center;
  color: grey;
  padding: 3px;
  font-size: 80%;
  margin: auto 0;
}

.picks {
  display: grid;
  grid-template-columns: 10% 70% 20%;
  grid-template-rows: auto;
  padding-bottom: 50px;
}

.team-pick {
  text-align: left;
  padding: 3px;
}

.perfect::before {
  content: "▲ ";
  /* Hacky way to ensure width's are the same */
  opacity: 0;
}

.better::before {
  content: "▲ ";
  color: green;
}

.worse::before {
  content: "▼ ";
  color: red;
}

.grid {
  display: grid;
  grid-template-columns: 20% 80%;
}

.table {
  width: 25%;
  min-width: 350px;
  padding-bottom: 20px;
}

.team {
  text-align: left;
  padding-bottom: 5px;
}

.total-title {
  text-align: right;
  border-top: 2px solid var(--accent);
  padding-top: 2px;
  font-weight: 600;
  color: var(--dark);
}

.total-points {
  border-top: 2px solid var(--accent);
  padding-top: 2px;
  font-weight: 600;
  color: var(--dark);
}
